<template>
  <div class="sandUrlUpload">
    <img class="sandUrlUpload-img" :src="getImg(type)">
    <div
      class="sandUrlUpload-content"
      :class="{'sandUrlUpload-content--focus': isFocus}">
      <el-input
        class="sandUrlUpload-input"
        type="textarea"
        ref="textareaRef"
        :autosize="{ minRows: 1, maxRows: 5 }"
        :placeholder="$t(`detection.tab.${type}Placeholder`)"
        @keyup.enter="toConfig"
        v-model="textarea"
        resize="none"
        maxlength="2048"
        @focus="focusInput"
        @blur="isFocus=false">
      </el-input>
      <el-icon class="icon-right" v-if="textarea.length" :color="$isDark() ? '#fff' : '#242933'" :size="24" @click="toConfig"><right /></el-icon>
    </div>
    <!-- <el-button @click="toConfig" class="sandUrlUpload-btn">确定</el-button> -->
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, defineExpose, getCurrentInstance } from 'vue'
import { useState } from '@/hooks/useVuex'
import { useStore } from 'vuex'
// import { toRaw } from '@vue/reactivity'
import { Right } from '@element-plus/icons-vue'
import { detectionPermise } from './permise'
import { getUrlState } from 'services/detection.js'
import emitter from '@/app/emitter.js'
import { checkLogin } from 'services/auth'
import { t } from 'app/i18n'
// import { useI18n } from 'vue-i18n'

// const { t } = useI18n()
const gProperties = getCurrentInstance().appContext.config.globalProperties
const { userInfo } = useState('user', ['userInfo'])
const store = useStore()
const urlList = []
const textarea = ref('')
const textareaRef = ref()
const isFocus = ref(false)

const textMap = {
  hash: {
    // placeholder: '输入或粘贴 MD5、SHA1或SHA256，批量提交时，多个hash之间需换行',
    placeholder: t('other.views.sendurlupload.hashPleaceholder'),
    reg: /(^[a-fA-F0-9]{32}$)|(^[a-fA-F0-9]{40}$)|(^[a-fA-F0-9]{64}$)/,
    msg1: t('other.views.sendurlupload.hashMsg1'),
    msg2: t('other.views.sendurlupload.hashMsg2'),
    msg3: t('other.views.sendurlupload.hashMsg3')
  },
  url: {
    placeholder: t('other.views.sendurlupload.urlPleaceholder'),
    reg: /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+.+$/,
    msg1: t('other.views.sendurlupload.urlMsg1'),
    msg2: t('other.views.sendurlupload.urlMsg2'),
    msg3: t('other.views.sendurlupload.urlMsg3')
  }
}
let quotaMsg = {}

const props = defineProps({
  type: {
    type: String,
    default: 'url'
  }
})
// eslint-disable-next-line no-undef
const emit = defineEmits(['submit'])

onMounted(() => setQuotaMsg())

function setQuotaMsg() {
  const group = userInfo.value.group || 'visitor'
  quotaMsg = detectionPermise[group]
  for (const k in quotaMsg) {
    if (quotaMsg[k].msg) {
      quotaMsg[k].msg = t(`permise.${group}.${k}.msg`)
    }
  }
  if (userInfo.value.quota) {
    const reg = /\b\d{1,3}(,\d{3})*|\b\d+\b/g // 如果有自定义配额 替换上面循环中设置的在国际化文件中写死的固定配额
    quotaMsg.fileSize.value = userInfo.value.quota['submit.max_file_size'].quota
    quotaMsg.fileSize.msg = t('detection.file_size_limit', [`${quotaMsg.fileSize.value / 1024 / 1024}M`])
    quotaMsg.once.value = userInfo.value.quota['submit.max_count_once'].quota
    quotaMsg.once.msg = quotaMsg.minTimes.msg.replace(reg, quotaMsg.once.value)
    quotaMsg.minTimes.value = userInfo.value.quota['submit.task_frequency_1m'].quota
    quotaMsg.minTimes.msg = quotaMsg.minTimes.msg.replace(reg, quotaMsg.minTimes.value)
    quotaMsg.dayTimes.value = userInfo.value.quota['submit.task_frequency_1d'].quota
    quotaMsg.dayTimes.msg = quotaMsg.dayTimes.msg.replace(reg, quotaMsg.dayTimes.value)
    // quotaMsg.monthTimes.value = userInfo.value.quota['submit.task_frequency_30d'].quota
  }
}

async function keyupEnter (e) {
  if (textarea.value === '') return
  let inputList = textarea.value.split('\n').map(item => item.trim()).filter(item => item !== '')
  const length = inputList.length
  if (length === 0) {
    textarea.value = ''
    return
  }
  inputList = [...new Set(inputList)]
  // 重复的给提示
  if (length !== inputList.length) {
    store.commit('app/showMessage', { type: 'error', message: textMap[props.type].msg3, duration: 1000 })
  }
  // 过滤和已经添加了的相同的
  if (inputList.some(item => urlList.some(i => i.value === item))) {
    inputList = inputList.filter(item => urlList.every(i => i.value !== item))
    store.commit('app/showMessage', { type: 'error', message: textMap[props.type].msg3, duration: 1000 })
  }
  // 单独考虑hash
  if (props.type === 'hash') {
    urlList.push(...inputList.map(item => ({
      type: textMap[props.type].reg.test(item.toLowerCase()),
      value: item,
      name: item
    })))
    textarea.value = ''
    if (urlList.length > quotaMsg.once.value) {
      commitMsg(quotaMsg.once.msg)
    }
    return
  }
  // 判断url错误
  await getUrlState({
    urls: inputList
  }).then(res => {
    for (const key in res) {
      urlList.push({
        type: key.length < 1990 && res[key],
        value: key,
        name: key
      })
    }
    textarea.value = ''
    // if (urlList.length > quotaMsg.once.value) {
    //   commitMsg(quotaMsg.once.msg)
    // }
  }).catch(() => {
    store.commit('app/showMessage', { type: 'error', message: t('other.views.sendurlupload.netDelay'), duration: 1000 })
  })
}
function getImg (name) {
  return gProperties.$isDark() ? require(`@/assets/img/detection/${name}Logo.png`) : require(`@/assets/img/detection/${name}Logo-light.png`)
}

function commitMsg (msg) {
  store.commit('app/closeMessage')
  store.commit('app/showMessage', {
    type: 'error',
    message: msg,
    duration: 0,
    dangerouslyUseHTMLString: true,
    toPrice: true,
    toUpgrade: userInfo.group !== 'enterprise',
    toGroup: userInfo.group === 'professional' ? 'enterprise' : 'professional',
    showClose: true
  })
}

function focusInput () {
  checkLogin().then((res) => {
    if (!res.is_login) {
      emitter.emit('showLogin')
      textarea.value = ''
      textareaRef.value.blur()
    } else {
      isFocus.value = true
    }
  })
}

async function toConfig () {
  await keyupEnter()
  store.commit('app/closeMessage')
  if (urlList.length === 0) {
    store.commit('app/showMessage', { type: 'error', message: textMap[props.type].msg1, duration: 1000 })
    return
  }
  if (urlList.some(item => !item.type)) {
    store.commit('app/showMessage', { type: 'error', message: textMap[props.type].msg2, duration: 1000 })
    // return
  }
  // if (!wrongJudgment()) return
  emit('submit', urlList, props.type)
}

defineExpose({
  toConfig,
  textarea
})
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.sandUrlUpload {
  padding: 0px 40px;
  min-height: 262px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-img  {
    height: 50px;
    display: block;
    margin:0 auto 19px;
  }
  &-content  {
    border: 1px solid var(--color-text-3);
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    padding-right:6px;
    box-sizing: border-box;
    &--focus  {
      border: 1px solid var(--color-primary);
    }
    .icon-right  {
      cursor: pointer;
    }
  }
  &-urlList  {
    max-height: 104px;
    overflow: auto;
    padding: 10px 24px 10px 16px;
    border-bottom: 1px solid var(--color-text-3);
    &__item  {
      width: 100%;
    }
    &__line  {
      height: 24px;
      line-height: 24px;
      max-width: calc(100% - 20px);
      display: inline-block;
      padding: 0 10px;
      background-color: var(--color-bg-1);
      border-radius: 4px;
      margin-bottom: 6px;&:last-child  {
        margin-bottom: 0px;
      }
    }
    &__icon  {
      margin-right: 8px;
      margin-bottom: 5px;
    }
    &__text  {
      display: inline-block;
      max-width: calc(100% - 42px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__close  {
      cursor: pointer;
      margin-left: 10px;
      margin-bottom: 7px;
    }
  }
  &-input  {
    & :deep(.el-textarea__inner) {
      width: 100%;
      min-height: 40px;
      border: none !important;
      box-shadow: none !important;
      background: transparent !important;
      color: var(--color-url-input);
      padding: 5px 36px 5px 15px;
      &::-webkit-scrollbar{width:0px;}
      &::placeholder  {
        font-size: 14px;
        // color: #858898;
        color: var(--color-text-3);
      }
    }
  }
  &-btn  {
    @include btn();
  }
}

[data-theme='light'] {
  .sandUrlUpload {
    &-content {
      border-color: #DFE1E6;
    }
  }
}
</style>
